import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { properties } from 'src/environments/properties';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Output() pageTitle = new EventEmitter();
  @Output() pageDesc = new EventEmitter();
  storeId: string;

  breadCrumbLinks = [
    {
      title: 'Home',
      url: '/'
    }
  ];

  constructor(private router: Router) { }

  ngOnInit() {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        this.breadCrumbMaker(val);
      }
    });
  }

  setTitleDesc(title, desc) {
    this.pageTitle.emit(title);
    this.pageDesc.emit(desc);
  }

  breadCrumbMaker(val) {

    // Default Home Page
    let title = 'eCom Operations App';
    if (window.innerWidth <= 768) {
      title = 'EOA';
    }
    this.breadCrumbLinks = [{ title: 'Home', url: '/' }];
    this.setTitleDesc(title, 'Application for configuring eCom Operations');

    for (let page of properties.homePage) {
      for (let pageData of page.pageData) {

        // All Pages Without Dynamic Store ID
        if (pageData.route === val.url) {
          this.breadCrumbLinks = [{ title: 'Home', url: '/' }, { title: pageData.pageName, url: val.url }];
          this.setTitleDesc(pageData.pageName, pageData.desc);
        }

        // Store Update Page - Dynamic Store ID
        if (val.url.indexOf('/store/update/') > -1) {
          this.storeId = val.url.split('/')[3];
          this.setTitleDesc('Update Store', 'Update settings for Store ID: ' + this.storeId);

          this.breadCrumbLinks = [
            { title: 'Home', url: '/' },
            { title: 'Search Store', url: 'store/update' },
            { title: 'Update Store', url: val.url }
          ];
        }

        // 3PL Page - Dynamic Store ID
        if (val.url.indexOf('/store/tpl-store-update') > -1) {
          this.setTitleDesc('3PL Update', 'Update settings for a 3PL Partner');

          this.storeId = val.url.split('/')[3];
          this.breadCrumbLinks = [
            { title: 'Home', url: '/' },
            { title: 'Search Store', url: 'store/update' },
            { title: 'Update Store', url: '/store/update/' + this.storeId },
            { title: '3PL Update', url: val.url }
          ];
        }

        // WGD Page - Dynamic Store ID
        if (val.url.indexOf('/store/wgd-store-update') > -1) {
          this.setTitleDesc('WGD Update', 'Update settings for a White Glove Delivery');

          this.storeId = val.url.split('/')[3];
          this.breadCrumbLinks = [
            { title: 'Home', url: '/' },
            { title: 'Search Store', url: 'store/update' },
            { title: 'Update Store', url: '/store/update/' + this.storeId },
            { title: 'WGD Update', url: val.url }
          ];
        }

        // Express Page - Dynamic Store ID // Hiding as part of NON-OPP-DECOMMISION
        // if (val.url.indexOf('/store/express-setting-update') > -1) {
        //   this.setTitleDesc('Express Update', 'Update settings for an Express ');

        //   this.storeId = val.url.split('/')[3];
        //   this.breadCrumbLinks = [
        //     { title: 'Home', url: '/' },
        //     { title: 'Search Store', url: 'store/update' },
        //     { title: 'Update Store', url: '/store/update/' + this.storeId },
        //     { title: 'Express Update', url: val.url }
        //   ];
        // }

        // Dark Store Page - Dynamic Store ID
        if (val.url.indexOf('/store/dark-store-setting-update') > -1) {
          this.setTitleDesc('Dark Store Update', 'Update settings for Dark Store ');

          this.storeId = val.url.split('/')[3];
          this.breadCrumbLinks = [
            { title: 'Home', url: '/' },
            { title: 'Search Store', url: 'store/update' },
            { title: 'Update Store', url: '/store/update/' + this.storeId },
            { title: 'Dark Store Update', url: val.url }
          ];
        }

        // Override Approvals - Dynamic ID
        if (val.url.indexOf('/slot/override/approvals') > -1) {
          let approvalTitle = 'Override Approval Review';
          if (window.innerWidth <= 768) {
            approvalTitle = 'Override Review';
          }
          this.setTitleDesc(approvalTitle, 'Director page for approving or rejecting slot override.');
          this.breadCrumbLinks = [
            { title: 'Home', url: '/' },
            { title: approvalTitle, url: val.url }
          ];
        }

      }
    }

  }

}
