import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
// import { environment } from '../../../environments/environment';
import { properties } from '../../../environments/properties';
import { BehaviorSubject, Observable } from 'rxjs';
import { EnvService } from '../context/env.service';

interface UserConfig {
  role: string;
  group: Array<any>;
}
@Injectable({
  providedIn: 'root'
})
export class UserService {
  signInUrl: string;

  // private AUTH_URL = environment.authConfig.pcfUrl;
  public token = '';
  public current_user_profile = '';
  public user: string;
  private userConfig = new BehaviorSubject<object>(null);
  private fullName = new BehaviorSubject<string>(null);
  public isLoggedIn = new BehaviorSubject<boolean>(null);
  private userValue = new BehaviorSubject<string>(null);


  constructor(private http: HttpClient, private router: Router, private envService: EnvService) {
    if (localStorage.getItem('ldapToken')) {
      this.setLoggedState(true);
    }
  }

  get UserValue() : Observable<string> {
    return this.userValue.asObservable();
  }

  get UserConfig(): Observable<any> {
    return this.userConfig.asObservable();
  }

  get userConfigVal() {
    return localStorage.getItem('user-config');
  }

  get loggedState(): Observable<boolean> {
    return this.isLoggedIn.asObservable();
  }

  get UserName(): Observable<string> {
    return this.fullName.asObservable();
  }

  setUserValue(user: string, name?: string) {
    this.userValue.next(user);
    this.user = user;
    if (name) {
      this.fullName.next(name);
    }
  }

  setLoggedState(loggedIn: boolean) {
    this.isLoggedIn.next(loggedIn);
  }

  setRole(role: object) {
    // console.log('in set role:', role);
    this.userConfig.next(role);
  }

  isReadOnly(group?) {
    let accessGroup = group || [];
    let readOnly = true;
    // Dynamically set access group based on page being accessed vs property mapping
    for (let page of properties.homePage) {
      for (let group of page.pageData) {
        if (group.route === this.router.url) {
          accessGroup = group.group;
        }
      }
    }
    // Subscribe to UserConfig to get user's latest groups and match against access group
    this.UserConfig.subscribe(data => {
      const config = data;
      const groupArray = this.diff(config.group, accessGroup);

      if (groupArray && groupArray.length >= 1) {
        if (groupArray.find(a => a.includes('approve')) || groupArray.find(a => a.includes('write'))) {
          readOnly = false;
        }
      }

    });
    return readOnly;
  }

  diff(arr, arr2) { 
    let ret = [];
    arr.sort();
    arr2.sort();
    arr.some(a => {
      arr2.some(b => {
        if (a.includes(b)) {
          ret.push(a)
        }
      })
    })
    return ret;
  };

  logOut() {
    // Please do not delete the bottom commented out code.
    // const encodedRedirectUri = encodeURIComponent(window.location.origin);
    // window.location.href = 'https://login.microsoftonline.com/b7f604a0-00a9-4188-9248-42f3a5aac2e9/oauth2/v2.0/logout?post_logout_redirect_uri=' + encodedRedirectUri;

    this.clearCacheStorage().then(() => {
      window.location.href = 'https://login.microsoftonline.com/b7f604a0-00a9-4188-9248-42f3a5aac2e9/oauth2/v2.0/logout';
    });
  }

  signIn() {
    const encodedRedirectUri = encodeURIComponent(window.location.origin);
    localStorage.setItem('redirect_url', window.location.pathname + window.location.search);
    this.signInUrl = this.envService.environment.LDAP_AUTHORIZE + `?client_id=${this.envService.environment.LDAP_CLIENT_ID}&response_mode=query&protectedtoken=true&response_type=code&resource=${this.envService.environment.LDAP_CLIENT_ID}&redirect_uri=${encodedRedirectUri}&scope=offline_access profile openid`;

    window.location.href = this.signInUrl;
  }

  clearCacheStorage(): Promise<Promise<boolean>[][]> {
    return caches.keys().then(cacheKeys =>
      Promise.all(
        cacheKeys.map(cacheKey => {
          const ngswRegex = /^(ngsw).*/;
          if (ngswRegex.test(cacheKey)) {
            return caches
              .open(cacheKey)
              .then(cache => cache.keys().then(requests => requests.map(req => cache.delete(req))));
          }
        })
      )
    );
  }
}
