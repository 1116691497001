import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { properties } from '../../environments/properties';
import { UserService } from '../shared/services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  pagesTxt = 'Login';
  isLoggedIn = false;
  pages = properties.homePage;
  userConfig: any = {};
  noGroup = false;
  accessGroup: any;

  constructor(private userService: UserService, private router: Router) {
    this.getLoginStage();
    this.getUserConfig();
  }

  ngOnInit() { }

  setRedirectUrl(page) {
    window.sessionStorage.setItem('redirectUrl', page.route);
  }

  checkPageGroup() {
    // console.log(this.userConfig.group);
    for (let page of this.pages) {
      for (let pages of page.pageData) {
        const groupArray = this.diff(this.userConfig.group, pages.group);
        if (groupArray.some(a => pages.group.some(b => a.includes(b)))) {
          page.showTitle = true;
          pages.showPage = true;
        }
        if (pages.group[0] === 'capacity' || pages.group[0] === 'help') {
          page.showTitle = true;
          pages.showPage = true;
        }
      }
    }
  }

  onNavigate(pages) {
    pages.isExternalLink ? window.open(pages.route, '_self') : this.router.navigate([pages.route]);
  }

  diff(arr, arr2) {
    let ret = [];
    arr.sort();
    arr2.sort();
    arr.some(a => {
      arr2.some(b => {
        if (a.includes(b)) {
          ret.push(a);
        }
      });
    });
    return ret;
  }

  getLoginStage() {
    this.userService.loggedState.subscribe((data) => {
      this.isLoggedIn = data;
      if (this.isLoggedIn) {
        this.pagesTxt = 'Go Now';
      } else {
        this.pagesTxt = 'Disabled (not logged in)';
      }
    });
  }

  getUserConfig() {
    this.userService.UserConfig.subscribe((data) => {
      // console.log(data);
      this.userConfig = data;
      this.checkPageGroup();
    });
  }
}
