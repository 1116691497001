import { Component, OnInit, Input, AfterContentChecked, ChangeDetectorRef, Inject } from '@angular/core';
// import { BroadcastService, MsalService } from '@azure/msal-angular';
import { Router, NavigationStart } from '@angular/router';
import { UserService } from '../../services/user.service';
import { DOCUMENT } from '@angular/common';
// import { environment } from '../../../../environments/environment';
import { PageService } from '../../services/page.service';
import { properties } from '../../../../environments/properties';
import { EnvService } from '../../context/env.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterContentChecked {
  linkData = properties.homePage;

  title = 'eCom Operations App';
  @Input() user = '';
  fullName: string;

  hideLogout = true;
  refreshPage: boolean;
  showMenu = false;
  stickyHeader = false;

  environment: string;
  pageTitle: string;
  pageDesc: string;

  faqGoTo = 1;

  breadCrumbLinks = [
    {
      title: 'Home',
      url: '/'
    }
  ];

  routeLink = '/';

  storeId: string;

  azureLogin = this.envService.environment.LDAP_AUTHORIZE +
    `?client_id=${this.envService.environment.LDAP_CLIENT_ID}
  &response_mode=query
  &protectedtoken=true
  &response_type=code
  &resource=${this.envService.environment.LDAP_CLIENT_ID}
  &redirect_uri=${window.location.origin}`;

  constructor(private router: Router, private userService: UserService,
    private pageService: PageService, private envService: EnvService,
    @Inject(DOCUMENT) private document: Document,
    private cdref: ChangeDetectorRef) {
    this.environment = this.envService.environment.ENVIRONMENT_ABBRV;
  }

  ngOnInit() {
    this.userService.UserValue.subscribe(data => {
      this.user = data;
    });
    this.userService.UserName.subscribe(data => {
      this.fullName = data || localStorage.getItem('user-id');
    });
    this.userService.loggedState.subscribe(data => {
      this.hideLogout = !data;
    });

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        this.faqGoTo = 1;
        this.pageTitle = 'eCom Operations App';

        // console.log(val);

        // this.checkSession();
        if (val.url === '/') {
          this.refreshPage = true;
        }

        // FAQ Go to pages
        if (val.url === '/slot/slotcapacity') {
          this.faqGoTo = 2;
        }

        if (val.url === '/slot/exceptions' || val.url === '/slot/override/history') {
          this.faqGoTo = 3;
        }

        if (val.url === '/levers/ott/exceptions') {
          this.faqGoTo = 4;
        }

        // To Make Breadcrumb Links on NavigationStart
        this.breadCrumbMaker(val);
      }
    });
  }

  breadCrumbMaker(val) {

    // Default Home Page
    let title = 'eCom Operations App';
    if (window.innerWidth <= 768) {
      title = 'EOA';
    }
    this.routeLink = '/';

    for (let page of properties.homePage) {
      for (let pageData of page.pageData) {

        // All Pages Without Dynamic Store ID
        if (pageData.route === val.url) {
          this.breadCrumbLinks = [{ title: 'Home', url: '/' }, { title: pageData.pageName, url: val.url }];
          this.pageTitle = pageData.pageName
        }

        // Store Update Page - Dynamic Store ID
        if (val.url.indexOf('/store/update/') > -1) {
          this.pageTitle = 'Update Store';
          this.storeId = val.url.split('/')[3];
          this.stickyHeader = true;
          this.routeLink = '/store/update';
        }

        // Store Capacity - Dyanamic Query
        if (val.url.indexOf('/promise-platform/store-capacity?') > -1) {
          this.pageTitle = 'Manage Store Capacity';
          this.routeLink = '/';
        }

        // 3PL Page - Dynamic Store ID
        if (val.url.indexOf('/store/tpl-store-update') > -1) {
          this.pageTitle = '3PL Update';
          this.storeId = val.url.split('/')[3];

          this.routeLink = '/store/update/' + this.storeId;
        }

        // WGD Page - Dynamic Store ID
        if (val.url.indexOf('/store/wgd-store-update') > -1) {
          this.pageTitle = 'WGD Update';
          this.storeId = val.url.split('/')[3];

          this.routeLink = '/store/update/' + this.storeId;
        }

        //Store Capacity Overrides Tracker- Dynamic Store ID
        if (val.url.indexOf('/promise-platform/piece-count') > -1) {
          this.pageTitle = 'Store Capacity Overrides Tracker';
          this.storeId = val.url.split('/')[3];

          this.routeLink = '/';
        }

        // Express Page - Dynamic Store ID   // Hiding as part of NON-OPP-DECOMMISION
        // if (val.url.indexOf('/store/express-setting-update') > -1) {
        //   this.pageTitle = 'Express Update';
        //   this.storeId = val.url.split('/')[3];

        //   this.routeLink = '/store/update/' + this.storeId;
        // }

        // Dark Store Page - Dynamic Store ID
        if (val.url.indexOf('/store/dark-store-setting-update') > -1) {
          this.pageTitle = 'Dark Store Update';
          this.storeId = val.url.split('/')[3];
          this.routeLink = '/store/dark-store-update/' + this.storeId;
        }

        // Override Approvals - Dynamic ID
        if (val.url.indexOf('/slot/override/approvals') > -1) {
          this.pageTitle = 'Override Approval Review';
          if (window.innerWidth <= 768) {
            this.pageTitle = 'Override Review';
          }
        }

        // Multi banner store update
        if (val.url.indexOf('/store/zipcodes-update') > -1) {
          this.pageTitle = 'Multi Banner ZIP Codes';
          if (window.innerWidth <= 768) {
            this.pageTitle = 'Multi Banner ZIP Codes';
          }
          this.routeLink = '/store/multibanner-zipcodes';
        }

        // Multi Banner Fulfillment Update
        if (val.url.indexOf('/store/fulfillment-update') > -1) {
          this.pageTitle = 'Multi Banner ZIP Codes';
          if (window.innerWidth <= 768) {
            this.pageTitle = 'Multi Banner ZIP Codes';
          }
          this.routeLink = '/store/multibanner-zipcodes';
        }

      }
    }

  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }


  checkSession() {
    this.userService.loggedState.subscribe(data => {
      this.hideLogout = !data;
    });
  }

  logout() {
    this.hideLogout = true;
    sessionStorage.removeItem('token');
    localStorage.clear();
    // this.mslService.logout();
    if (this.refreshPage || this.showMenu) {
      window.location.reload();
    }
    this.userService.setLoggedState(false);
    // this.router.navigateByUrl('/');
    const encodedRedirectUri = encodeURIComponent(window.location.origin);
    // this.msalService.logout();
    window.location.href = 'https://login.microsoftonline.com/b7f604a0-00a9-4188-9248-42f3a5aac2e9/oauth2/v2.0/logout?post_logout_redirect_uri=' + encodedRedirectUri;
  }

  showMobileMenu() {
    this.showMenu = !this.showMenu;
    if (this.document.body.classList.contains('menu-open')) {
      this.document.body.classList.remove('menu-open');
    } else {
      this.document.body.classList.add('menu-open');
    }
  }

  handlePageTitle(event) {
    this.pageTitle = event;
  }

  handlePageDesc(event) {
    this.pageDesc = event;
  }

  setFAQPage() {
    this.pageService.setFAQPage(this.faqGoTo);
    this.router.navigate(['/help/faq']);
  }

}
